// Main scripts file
import './js/index';

// Main styles file
import './scss/index.scss';

// Images
import './media/sylius-logo.png';

// Font awesome icons
import './js/fontawesome';
